import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpdateLocationButton = ({ setIsGeoLocationActive }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Button
                type='primary'
                ghost
                onClick={() => setIsGeoLocationActive(true)}
                style={{
                    width: 'fit-content',
                    marginBottom: '10px',
                }}
                icon={<FontAwesomeIcon icon={['far', 'map-pin']} />}
            >
                Update location
            </Button>
        </div>
    );
};

export default UpdateLocationButton;
