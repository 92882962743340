import React, { useState, useEffect } from 'react';
import apiRequest from '../util/apiRequest';
import { Form, Space, Modal, Button, message, Checkbox, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncFn } from 'react-use';
import { identity } from 'lodash';

import ObservationFormComments from './ObservationFormComments';
import SchemaFormFields from './SchemaFormFields';
import RequiredFormLegend from './RequiredFormLegend';
import ShowIfManagerOrLoc from './ShowIfManagerOrLoc';
import {
    stationUrl,
    makeStationUrl,
    models,
    stationFieldAddendum,
} from '../util/constants';
import ObservationFormSection from './ObservationFormSection';
import ObservationFormLocation from './ObservationFormLocation';

export default function ObservationFormStationForm({
    schema,
    project,
    onFinish = identity,
    onChooseStation = identity,
    editStation,
    basemap,
    isOffline,
    userId,
    stationPoint,
    setStationPoint,
}) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [saveStationState, saveStation] = useAsyncFn(
        async data => {
            const formData = Object.assign(data);

            // We will need to include the names of the project-specific observation attributes
            // in case the app is offline and the service worker needs these data to construct a response
            formData['attributes'] = schema.station.folders[0]?.fields.map(
                f => f.name
            );

            formData['ownerId'] = userId;

            const url = editStation
                ? makeStationUrl(editStation.stationId)
                : stationUrl;

            const response = editStation
                ? await apiRequest.put(url, formData)
                : await apiRequest.post(url, formData);
            return response.data;
        },
        [form, onFinish]
    );

    const [isGeoLocationActive, setIsGeoLocationActive] = useState(false);

    if (!saveStationState.loading) {
        if (saveStationState.error) {
            message.error('An error prevented saving a new station.');
        }
    }

    const resetFields = (form, editStation) => {
        if (editStation) {
            // Reset fields to their original values
            form.setFieldsValue({
                stationName: editStation.stationName,
                isPublic: editStation.isPublic,
                trusted: editStation.trusted,
                ...editStation.attributes,
            });
        } else {
            // Reset fields to empty for new station forms
            form.resetFields();
        }
    };

    // Initialize form fields on every load
    useEffect(() => {
        resetFields(form, editStation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, editStation]);

    const stationFields = stationFieldAddendum.concat(
        schema?.station?.folders[0]?.fields || []
    );

    const handleCancel = () => {
        setIsModalOpen(false);
        resetFields(form, editStation);
        setIsGeoLocationActive(false);
    };

    const handleSave = () => {
        form.submit();
    };

    const handleFinish = async values => {
        // We use latitude and longitude in the client because
        // that is what MapboxGL uses, but the API requires us
        // to rename them to y and x. Passing undefined
        // effectively removes the properties.
        const data = Object.assign(
            {
                schema: schema.name,
                x: values.longitude,
                y: values.latitude,
                wkid: 4326,
                public: values.isPublic,
            },
            values,
            {
                latitude: undefined,
                longitude: undefined,
                isPublic: undefined,
            }
        );

        const payload = await saveStation(data);
        if (!payload.result || (payload.result && payload.status !== 200)) {
            if (payload.status === 422) {
                message.error(payload.error);
            } else {
                message.error('An error prevented saving the station');
            }
        } else {
            // this removes the modal. we only want to this upon closing, canceling, or successfully
            // adding a station (this case) -- not when we fail to add one
            setIsModalOpen(false);
            resetFields(form, editStation);
            message.success('Saved station.');
            onFinish(payload.result);
        }
    };

    const handleFinishFailed = () => {
        message.error('One or more fields are incomplete or invalid');
    };

    const isDraftStation = editStation?.stationId?.includes('draft');

    const commentsSection = editStation && !isOffline && !isDraftStation && (
        <ShowIfManagerOrLoc locFieldValue={editStation?.ownerLocFieldValue}>
            <ObservationFormSection label='Comments'>
                <ObservationFormComments
                    id={editStation.stationId}
                    model={models.STATION}
                />
            </ObservationFormSection>
        </ShowIfManagerOrLoc>
    );

    const reviewSection = editStation && !isDraftStation && (
        <ShowIfManagerOrLoc locFieldValue={editStation?.ownerLocFieldValue}>
            <div
                className='observation__section'
                style={{ paddingTop: '26px' }}
            >
                <Space>
                    <Form.Item name='trusted' valuePropName='checked'>
                        <Checkbox>Mark as Reviewed</Checkbox>
                    </Form.Item>
                    <Form.Item name='isPublic' valuePropName='checked'>
                        <Checkbox>Public</Checkbox>
                    </Form.Item>
                </Space>
            </div>
        </ShowIfManagerOrLoc>
    );

    const editMenu = [
        {
            key: 'update-station',
            label: 'Update this station',
            onClick: () => setIsModalOpen(true),
        },
        {
            key: 'choose-new-station',
            label: 'Choose a different station',
            onClick: onChooseStation,
        },
    ];

    return (
        <>
            {editStation ? (
                <Dropdown menu={{ items: editMenu }} trigger={['click']}>
                    <Button
                        type='primary'
                        ghost
                        onClick={e => e.preventDefault()}
                        size='small'
                        icon={<FontAwesomeIcon icon={['far', 'pencil']} />}
                    >
                        Edit...
                    </Button>
                </Dropdown>
            ) : (
                <Button
                    type='primary'
                    ghost
                    icon={<FontAwesomeIcon icon={['far', 'plus-circle']} />}
                    onClick={() => setIsModalOpen(true)}
                    size='small'
                >
                    Add
                </Button>
            )}
            <Modal
                open={isModalOpen}
                title={
                    editStation
                        ? `Edit station ${editStation.stationName}`
                        : 'Create a new station'
                }
                maskClosable={false}
                footer={
                    isGeoLocationActive ? (
                        <Button
                            key='save'
                            type='primary'
                            onClick={() => setIsGeoLocationActive(false)}
                        >
                            Update
                        </Button>
                    ) : (
                        [
                            <Button key='cancel' onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key='save'
                                type='primary'
                                loading={saveStationState.loading}
                                onClick={handleSave}
                            >
                                {editStation ? 'Save' : 'Create'}
                            </Button>,
                        ]
                    )
                }
                onFinish={handleSave}
                onCancel={handleCancel}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    layout='vertical'
                    name='form'
                    initialValues={{ modifier: 'public' }}
                >
                    <RequiredFormLegend />
                    <ObservationFormLocation
                        project={project}
                        editStation={editStation}
                        basemap={basemap}
                        isOffline={isOffline}
                        form={form}
                        isGeoLocationActive={isGeoLocationActive}
                        setIsGeoLocationActive={setIsGeoLocationActive}
                        stationPoint={stationPoint}
                        setStationPoint={setStationPoint}
                    />
                    {!isGeoLocationActive && (
                        <>
                            {reviewSection}
                            {commentsSection}

                            <SchemaFormFields
                                fields={stationFields}
                                indicateRequired
                                useExpandingInputs
                            />
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
}
